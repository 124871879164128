import { Heading, TimeUtil, useFeatureFlags } from 'cuenect-web-core'
import parse from 'html-react-parser'
import i18n from 'i18next'
import React, { useEffect } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import {
  Button,
  ContentContainer,
  InnerContainer,
  LineContainer,
  Link,
  Paragraph,
} from '../components'
import { PageFrame, PageParams } from '../components/organisms/pageFrame'
import { ProgramContainer } from '../components/organisms/programContainer'
import { eventConfig } from '../config'
import { useVodContext } from '../utility'
import { isPostEvent, isPostStream } from './../config'

const RENAME_AUDITORIUM = '2021-10-06T04:00Z'
const HIDE_MEET_THE_EXPERT_TIME = '2021-10-07T16:00Z'

const getRenameAuditorium = () => {
  const now = TimeUtil.getNowUtc()

  return now.isAfter(RENAME_AUDITORIUM)
}

const getHideMte = () => {
  const now = TimeUtil.getNowUtc()

  return now.isAfter(HIDE_MEET_THE_EXPERT_TIME)
}

const ProgramPage = (params: PageParams) => {
  const {
    pageContext: { lang, program },
  } = params

  const { phase3 } = useFeatureFlags()
  const postEvent = isPostEvent()
  const { dispatch: vodDispatch } = useVodContext()

  const noFrame =
    new URLSearchParams(
      typeof window !== 'undefined' ? window.location.search : ''
    ).get('noFrame') === 'true'

  const forceFilterSlug =
    new URLSearchParams(
      typeof window !== 'undefined' ? window.location.search : ''
    ).get('forceFilterSlug') || undefined

  const [scroller, setScroller] = React.useState(null)

  const agendaProgram = program || 'stage'
  const { t } = useTranslation('program')
  const [loaded, setLoaded] = React.useState(false)

  const programMapping: { [index: string]: string } = {
    stage: `auditorium-${lang}`,
    'meet-the-expert': `tech-meetup-${lang}`,
    guidedtours: `guided-tour`,
  }

  useEffect(() => {
    i18n.changeLanguage(lang)
    setLoaded(true)
  }, [])

  return (
    <PageFrame
      {...params}
      noFrame={noFrame}
      onScrollerInit={elem => {
        setScroller(elem)
      }}
      pageName="program"
    >
      {loaded && scroller && (
        <ContentContainer>
          <InnerContainer width="100%">
            <LineContainer className="LineContainer">
              {!phase3 && (
                <>
                  <Heading>{parse(t('headline'))}</Heading>{' '}
                  <Paragraph style={{ marginBottom: 0 }}>
                    {parse(t('subline'))}
                  </Paragraph>
                </>
              )}
              {phase3 && !isPostStream() && (
                <>
                  <Heading>{parse(t('headlinePhase3'))}</Heading>
                  <Paragraph style={{ marginBottom: 0 }}>
                    {parse(t('subline'))}
                  </Paragraph>
                </>
              )}
              {phase3 && isPostStream() && (
                <>
                  <Heading>{parse(t('headlinePhase4'))}</Heading>
                  {!postEvent && (
                    <Paragraph style={{ marginBottom: 0 }}>
                      {parse(t('subline'))}
                    </Paragraph>
                  )}
                  {postEvent && (
                    <Paragraph style={{ marginBottom: 0 }}>
                      {parse(t('sublinePhase4'))}
                    </Paragraph>
                  )}
                </>
              )}
            </LineContainer>
          </InnerContainer>
          <ProgramContainer
            scroller={scroller}
            source={eventConfig.sources[lang]}
            subdivision={agendaProgram}
            slug={programMapping[agendaProgram]}
            postEvent={isPostEvent()}
            forceFilterSlug={forceFilterSlug}
          />
        </ContentContainer>
      )}
    </PageFrame>
  )
}

export default ProgramPage
